@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

.fc-day-grid-event.call-event, .fc-time-grid-event.call-event {
  background-color: #3788d8;
}
.fc-day-grid-event.call-event::before, .fc-time-grid-event.call-event::before {
  content: "\f095"; /* phone */
  font-family: Icons;
  float: right;
  margin: 0 3px;
}

.fc-day-grid-event.meeting-event, .fc-time-grid-event.meeting-event {
  background-color: #0e3263;
}
.fc-day-grid-event.meeting-event::before, .fc-time-grid-event.meeting-event::before {
  content: "\f0b1"; /* briefcase */
  font-family: Icons;
  float: right;
  margin: 0 3px;
}
